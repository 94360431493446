<template>
    <v-card
    class="mb-12"
    height="500px"
    >
        <v-alert type="error" v-if="errMessage != ''">
            {{errMessage}}
        </v-alert> 
        
        <v-simple-table
            fixed-header
            height="520px"
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Bezeichnung
                </th>
                <th class="text-left">
                    Wert
                </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{ item.name }}</td>
                </tr>
                <tr>
                    <td>Fläche/ha</td>
                    <td v-if="item.area_ha" >{{item.area_ha}}</td>
                    <td v-if="!item.area_ha"><v-icon color="error">mdi-alert-circle</v-icon> Keine Flächendaten vorhanden!</td>
                </tr>
                <tr>
                    <td>Anbauart</td>
                    <td>Blabla</td>
                </tr>
                <tr>
                    <td>Geodaten</td>
                    <td v-if="polyAvailable" ><v-icon >mdi-check-bold</v-icon></td>
                    <td v-if="!polyAvailable"><v-icon color="error">mdi-alert-circle</v-icon> Keine Geodaten vorhanden!</td>
                </tr>
                <tr v-if="item.cultivation_species_id == 1000">
                    <td>Sorte</td>
                    <td>{{item.meta.wine.type}}</td>
                </tr>
                <tr v-if="item.cultivation_species_id == 1000">
                    <td>Anbaudatum</td>
                    <td>{{item.meta.wine.date_of_cultivation}}</td>
                </tr>  
                <tr v-if="item.cultivation_species_id == 1000">
                    <td>Gassenbreite</td>
                    <td>{{item.meta.wine.row_distance}}m</td>
                </tr>     
                <tr v-if="item.cultivation_species_id == 1000">
                    <td>Stockabstand</td>
                    <td>{{item.meta.wine.grapevine_distance}}m</td>
                </tr>   
                <tr v-if="item.isLeased">
                    <td v-if="item.leased.leasedout">Pächter</td>
                    <td v-if="!item.leased.leasedout">Verächter</td>
                     <td>{{findContactName(item.leased.contact_id)}}</td>
                </tr>  
                <tr v-if="item.isLeased">
                    <td>Zahltag</td>
                    <td>{{item.leased.payday}}</td>
                </tr>   
               <tr v-if="item.isLeased">
                    <td>Läuft aus am</td>
                    <td>{{item.leased.expirationdate}}</td>
                </tr>   
                <tr v-if="item.isLeased">
                    <td>Pacht Betrag/Jahr</td>
                    <td>{{item.leased.amount}}</td>
                </tr>   

            </tbody>
            </template>
        </v-simple-table>                 
    </v-card>
</template>

<script>

    export default {

        props:{
            item: {
                required: false,
                type: Object,
                default: () => {
                    return  {
                        name: null,
                        area_ha: null,
                        area_ha_calculated: null,
                        cultivation_species: null,
                        isLeased: null,
                        polygon:[] ,                
                        meta:{
                            wine:{
                                type:null,
                                date_of_cultivation: null,
                                row_distance: null,
                                grapevine_distance: null
                            }
                        },    
                    }
                }
            },
            state: {
                required: false,
                type: Object,
                default: () => {
                    return  {
                        state:{
                            valid:false
                        }
                        // isEdit: false
                    }
                }
            }
        },
    
        data() {
            return {
                valid: false, 
                errMessage: "" ,                        
            }
    
        },

        computed:{
            polyAvailable(){
                return this.item.polygon ? this.item.polygon.length > 0 : false;
            },

            currentUser() {
                return this.$store.state.auth.user;
            },

            leasedName(){
                return ""
            },


            availableContacts: function() {
                const linkedContacts = JSON.parse(JSON.stringify( this.currentUser.linkedContacts))
                
                for (const item of linkedContacts) {
                    // console.debug("-----------------", item)
                    item.name = item.firstname + " " + item.lastname
                    
                }

                return linkedContacts
            },       


        },
    
        methods:{

            findContactName(id){
                console.log('findContact', id)
                let filter = this.availableContacts.find(item => item.id === id);
                let res = "hdsgfd"
                if(filter)
                    return filter.firstname + " " + filter.lastname
                    // console.debug("filter", filter)

                return res


            }        
     
        },
    
        watch: { 
      
            // item: function(newVal) { // watch it
            //     console.log('Prop changed: item', newVal, this.item)
            // },
    
            // valid: function(newVal) { // watch it
            //     console.log('Prop changed: valid', newVal, this.item)
            //     this.state.valid = this.valid; 
            // }, 
        },
    
        mounted() {
            // console.debug('mounted', this.item);
        }
    }
</script>
    